import "./price-card.scss";

import * as React from "react";
import { PropsWithChildren } from "react";

import { Technique } from "../../../contexts/techniques/domain/Technique";

interface PropsPriceCard extends PropsWithChildren<any> {
  technique: Technique;
}

const PriceCard: React.FC<PropsPriceCard> = (props: PropsPriceCard) => {
  const { technique } = props;

  return (
    <div className={"m-price-card"}>
      <div className="m-card m-card--border m-card--no-shadow">
        <div className="is-text-center">
          <p className="a-card__paragraph a-card__paragraph-number is-fs-title-xl">
            {technique.heroPrice.price}
            <span className={"a-currency"}>€</span>
          </p>
          <p className="a-card__paragraph">{technique.heroPrice.info}</p>
        </div>
      </div>
    </div>
  );
};

export default PriceCard;
