import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { PropsWithChildren } from "react";
import Helmet from "react-helmet";

import { address, contact, url } from "../../data/config";

interface PropsOthers extends PropsWithChildren<any> {
  service: string;
  description: string;
  image: string;
  slug: string;
}

const TechniqueMarkupSchema: React.FC<PropsOthers> = (props: PropsOthers) => {
  const data = useStaticQuery(
    graphql`
      query RatesTechniquesSchemaQuery {
        allGooglePlacesPlace {
          nodes {
            user_ratings_total
            rating
          }
        }
      }
    `
  );

  const markup = `{ 
    "@context": "https://schema.org",
    "@type": "Physiotherapy",
    "name": "${props.service}",
    "description": "${props.description}",
    "image": "${props.image}",
    "url": "${url}${props.slug}/",
    "telephone": "${contact.phone}",
     "address": {
        "@type": "PostalAddress",
        "streetAddress": "${address.streetAddress}",
        "addressLocality": "${address.city}",
        "postalCode": "${address.zipCode}",
        "addressCountry": "ES"
      },
    "openingHoursSpecification": {
    "@type": "OpeningHoursSpecification",
    "dayOfWeek": [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday"
    ],
    "opens": "11:00",
    "closes": "20:30"
  },
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "${data.allGooglePlacesPlace.nodes[0].rating}",
        "bestRating": "5",
        "worstRating": "1",
        "ratingCount": "${data.allGooglePlacesPlace.nodes[0].user_ratings_total}"
      }
  	}`;
  return (
    <Helmet>
      <script type="application/ld+json">{markup}</script>
    </Helmet>
  );
};

export default TechniqueMarkupSchema;
